import './App.css';
import banner from './assets/banner.png';
import mobileBanner from './assets/mobile-banner.png';
import cow from './assets/cow.svg';
import logo from './assets/logo.png';
import Btn from './components/Button/Button';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Web3Modal from "web3modal";
import { toast } from 'react-toastify';
import passAbi from "./abis/pass.json"
import cowAbi from "./abis/cow.json"

function App() {
  const [initiated, setInitated] = useState(false);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null)
  const [passContract, setPassContract] = useState(null);
  const [mintContract, setMintContract] = useState(null);
  const [passBalance, setPassBalance] = useState(null);
  const [mintAmount, setMintAmount] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);

  async function connect() {
    if (typeof window.ethereum === 'undefined') {
      toast.error('MetaMask is not installed!');
      return;
    }
    
    const providerOptions = {
      /* See Provider Options Section */
    };
    
    const web3Modal = new Web3Modal({
      network: "avax", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);
    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    
    await provider.send("eth_requestAccounts", []);

    try {
      await provider.send("wallet_switchEthereumChain", [{ chainId: "0xa86a" }]);
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask.
      // if (err.code === 4902) {
      if (err) {
        try {
          await provider.send(
            "wallet_addEthereumChain",
            [
              {
                chainId: "0xa86a",
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc/"],
                chainName: "Avalanche Network",
                blockExplorerUrls: ["https://snowtrace.io/"],
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
              },
            ]);
        } catch (addError) {
          console.error(addError);
        }
      }
    }

    const signer = await provider.getSigner()
    // console.log(signer)
    
    setSigner(signer);
    setAddress(await signer.getAddress());

    return () => {
      // here unsubscribe from the doAccountThings handler,
      // whatever that syntax is.
    }
  };

  useEffect(() => {
      connect()  
  }, [])

  useEffect(() => {
    if(signer && !initiated){
      console.log("signer")
      setInitated(true);
      // console.log(signer)
      window.ethereum.on("accountsChanged", (accounts) => {
        // console.log(accounts);
        if(accounts.length === 0) {
          setAddress(null)
        } else {
          setAddress(accounts[0])
        }
      });
    
      // Subscribe to chainId change
      console.log("subcribe to chainchanged")
      window.ethereum.on("chainChanged", (chainId) => {
        // console.log(chainId);
        if(chainId !== "0xa86a") {
            toast.error("Wrong Network!", {theme: "dark"})
        }
      });
    }
  }, [signer, initiated])

  useEffect(() => {
    async function prepareContracts() {
      if(signer){
        setPassContract(new ethers.Contract("0xe56d4956baa326b9f0bcc73a0ce6d16a55277537", passAbi, signer))
        setMintContract(new ethers.Contract("0x58c4134f0dab544fe52d00a33faf791d55a61d4f", cowAbi, signer));

      }
    }
    prepareContracts()
  }, [signer])

  useEffect(() => {
    async function getBalance(){
      if(address && passContract){
        console.log("update bal")
        const bal = parseInt((await passContract.balanceOf(address, 3)).toString())
        setPassBalance(bal)
        setMintAmount(bal)
      }  
    }
    getBalance()
  }, [address, passContract])

  useEffect(() => {
    async function getTotalSupply(){
      if(address && mintContract){
        console.log("update bal")
        const total = parseInt((await mintContract.totalSupply()).toString())
        setTotalSupply(total)
      }  
    }
    getTotalSupply()
  }, [address, mintContract])


  async function mint() {
    const amount = Number(mintAmount)
    if(amount > passBalance) {
      toast.error("Error: You don't have enough passes to mint " + mintAmount + " Cows!")
      return
    }
    try {
      let tx = await mintContract.redeem(amount);
      await tx.wait()
    } catch(e) {
      console.log(e)
      toast.error(e.message.split("execution reverted: ")[1].split("\"")[0])
      return
    }

    toast.success("Minted " + mintAmount + " Cows!")
    const bal = parseInt((await passContract.balanceOf(address, 3)).toString())
    setPassBalance((bal).toString())
    setMintAmount(bal)
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className='container' style={{marginLeft: "20px"}}>
          <img className='pixel' src={logo} alt="logo" />
          <a style={{textDecoration: "none"}}
              href='https://cheese.game'
          >
            <h1 style={{marginLeft: "10px", fontSize: "40px"}}>CheeseGame</h1>
          </a>
        </div>
        <div style={{marginRight: "20px"}}>
          <a style={{textDecoration: "none"}}
                href={`https://snowtrace.io/address/${address}`}
            >
          <h1>{address && address.slice(0,6) + "..." + address.slice(-4)}</h1>
          </a>
        </div>
      </header>
      <div className='banner'>
        <picture>
                <source media="(min-width: 1500px)" srcSet={banner}/>
                <img className='pixel' src={mobileBanner} alt="Cow Banner"/>
        </picture>
      </div>
      <div className={`mint-body row`}>
          <div className={`mint-box column`}> 
            <img style={{maxHeight: "150px"}} className='pixel' src={cow} alt='cow' />
            <h1>Cow Minting</h1>
            <div className="progress-bar">
              <div className="mint-progress-text">
                  {totalSupply} / 1000 cows minted
              </div>
              <span className="progress-bar-fill" style={{width: totalSupply / 1000 * 100 + "%"}}>
              </span>
            </div>
            <div className="balance">
              <h2>You have <span style={{color:"#52A560"}}>{passBalance ? passBalance.toString() : "0"}</span> passes available</h2>                
            </div>
            <div className='input'>
              <input 
                defaultValue={mintAmount > 1 ? 1 : 0} 
                onChange={(event) => setMintAmount(event.target.value)}
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                max={passBalance}
              />
              {address !== null 
              ? <Btn onClick={mint} text="REDEEM" />
              : <Btn onClick={connect} text="CONNECT" />
              }
            </div>
            <div className="equiv">
              <h2>1 CheezPass = 1 Cow</h2>                
            </div>
          </div>
          <div className={`column`}>
            <h2>Cows have arrived! CheeseGame v2 now features 1000 unique 721 Cow NFTs, integrated into the upcoming Mazes game. Cows will soon produce MILK, a resource crucial in getting the most out of your Maze.</h2>

            <h2>Do you have your CHEEZ PASS? Mint your Cow today by burning the CHEEZ PASS NFT! Cows are randomly generated at mint. Any CHEEZ PASS has the chance to mint a rare Cow!</h2>  
          </div>
        </div>      
    </div>
  );
}

export default App;
