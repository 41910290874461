import * as React from 'react';
import { useEffect, useState } from "react";
import "./button.css"

function Btn(props) {
    const callback = props.onClick;

    const normalBtn = <><rect x="4" y="50" width="16" height="4" fill="#52A560"/>
    <rect x="4" y="4" width="16" height="42" fill="#5ECA70"/>
    <path d="M20 48H2V6H6V2H20" stroke="#7DD88D" strokeWidth="4"/>
    <g filter="url(#filter0_iii_124_16087)">
    <rect width="105" height="54" transform="translate(20)" fill="#5ECA70"/>
    </g>
    <g clipPath="url(#clip0_124_16087)">
    <path d="M145 50L145 54L125 54L125 4L149 4L149 50L145 50Z" fill="#52A560"/>
    <rect x="144" y="46" width="19" height="42" transform="rotate(-180 144 46)" fill="#5ECA70"/>
    <path d="M125 2.00002L143 2.00002L143 44L139 44L139 48L125 48" stroke="#7DD88D" strokeWidth="4"/>
    </g>
    <defs>
    <filter id="filter0_iii_124_16087" x="20" y="0" width="105" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.490196 0 0 0 0 0.847059 0 0 0 0 0.552941 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_124_16087"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-8"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.490196 0 0 0 0 0.847059 0 0 0 0 0.552941 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect1_innerShadow_124_16087" result="effect2_innerShadow_124_16087"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.321569 0 0 0 0 0.647059 0 0 0 0 0.376471 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect2_innerShadow_124_16087" result="effect3_innerShadow_124_16087"/>
    </filter>
    <clipPath id="clip0_124_16087">
    <rect width="24" height="54" fill="white" transform="translate(125)"/>
    </clipPath>
    </defs></>

    const hoverBtn = <><rect x="4" y="50" width="16" height="4" fill="#52A560"/>
    <rect x="4" y="4" width="16" height="42" fill="#7DD88D"/>
    <path d="M20 48H2V6H6V2H20" stroke="#5ECA70" strokeWidth="4"/>
    <g filter="url(#filter0_iii_124_16093)">
    <rect width="105" height="54" transform="translate(20)" fill="#7DD88D"/>
    </g>
    <g clipPath="url(#clip0_124_16093)">
    <path d="M145 50L145 54L125 54L125 4L149 4L149 50L145 50Z" fill="#52A560"/>
    <rect x="144" y="46" width="19" height="42" transform="rotate(-180 144 46)" fill="#7DD88D"/>
    <path d="M125 2.00002L143 2.00002L143 44L139 44L139 48L125 48" stroke="#5ECA70" strokeWidth="4"/>
    </g>
    <defs>
    <filter id="filter0_iii_124_16093" x="20" y="0" width="105" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0.792157 0 0 0 0 0.439216 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_124_16093"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-8"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0.792157 0 0 0 0 0.439216 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect1_innerShadow_124_16093" result="effect2_innerShadow_124_16093"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.321569 0 0 0 0 0.647059 0 0 0 0 0.376471 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect2_innerShadow_124_16093" result="effect3_innerShadow_124_16093"/>
    </filter>
    <clipPath id="clip0_124_16093">
    <rect width="24" height="54" fill="white" transform="translate(125)"/>
    </clipPath>
    </defs></>

    const activeBtn = <>
    <rect x="4" y="50" width="16" height="4" fill="#21865B"/>
    <rect x="4" y="4" width="16" height="42" fill="#5BB66B"/>
    <path d="M20 48H2V6H6V2H20" stroke="#5ECA70" strokeWidth="4"/>
    <g filter="url(#filter0_iii_124_16111)">
    <rect width="105" height="54" transform="translate(20)" fill="#5BB66B"/>
    </g>
    <g clipPath="url(#clip0_124_16111)">
    <path d="M145 50L145 54L125 54L125 4L149 4L149 50L145 50Z" fill="#21865B"/>
    <rect x="144" y="46" width="19" height="42" transform="rotate(-180 144 46)" fill="#5BB66B"/>
    <path d="M125 1.99999L143 1.99999L143 44L139 44L139 48L125 48" stroke="#5ECA70" strokeWidth="4"/>
    </g>
    <defs>
    <filter id="filter0_iii_124_16111" x="20" y="0" width="105" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0.792157 0 0 0 0 0.439216 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_124_16111"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-8"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0.792157 0 0 0 0 0.439216 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect1_innerShadow_124_16111" result="effect2_innerShadow_124_16111"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.52549 0 0 0 0 0.356863 0 0 0 1 0"/>
    <feBlend mode="normal" in2="effect2_innerShadow_124_16111" result="effect3_innerShadow_124_16111"/>
    </filter>
    <clipPath id="clip0_124_16111">
    <rect width="24" height="54" fill="white" transform="translate(125)"/>
    </clipPath>
    </defs>
    </>

    const [hover, setHover] = useState(false);
    const [pressed, setPressed] = useState(false);
    const [btn, setBtn] = useState(normalBtn);


    useEffect(() => {
        // console.log(pressed, hover)
        if(pressed) {
            setBtn(activeBtn);
            setTimeout(() => {setPressed(false)}, 200)
        } else if (hover) {
            setBtn(hoverBtn);
        } else {
            setBtn(normalBtn)
        }
    }, [hover, pressed])

        return (
            <svg className="cheez-button" width="149" height="54" viewBox="0 0 149 54" fill="none" xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => {
                    setPressed(true);
                    callback()
                }}
            >
                {btn}

                <foreignObject x="0" y="0" width="133" height="54">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <p style={{marginLeft: "10px"}}>{props.text}</p>
                    </div>
                </foreignObject>
            </svg>
        )
}

export default Btn;